<template>
  <div class="map">
    <h1>位置信息</h1>
    <!-- <div id="echarts_map" style="width: 600px;height:400px;"></div> -->
    <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="center" :zoom="zoom" ak="V4ZU4mwLSmLTrxg4HvLQHWxTU0CUaDX3">
      <bm-view style="width: 100%; height:1000px; flex: 1"></bm-view>
      <bm-marker v-for="marker of markers" :key="marker.id" :position="{lng: marker.lng, lat: marker.lat}" ></bm-marker>
    </baidu-map>
  </div>
</template>

<script>
import $ from 'jquery'
import * as echarts from 'echarts'
require('echarts/extension/bmap/bmap')
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'

export default {
  components: {
    BaiduMap,
    BmView,
    BmMarker,
  },
  data() {
    return {
      zoom: 12,
    }
  },
  methods : {
    ShowChartMap () {
      var ROOT_PATH = 'https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples';

      var chartDom = document.getElementById('echarts_map');
      var myChart = echarts.init(chartDom);
      var option;
      var p=this;
      var i=this.$store.state.current.master_i;

      $.get(ROOT_PATH + '/data/asset/data/lines-bus.json', function() {
          myChart.setOption(option = {
              bmap: {                
                  center: [p.$store.state.masters[i][3], p.$store.state.masters[i][2]],
                  zoom: 12,
              },
          });
      });
      option && myChart.setOption(option);
    },
  },
  computed:{
    markers: function() {
      var d=[]
      for (var i in this.$store.state.masters){
        d.push({
          lat: this.$store.state.masters[i][2],
          lng: this.$store.state.masters[i][3],
        })
      }
      return d
    },
    center: function() {
      return {
        lat: this.$store.state.masters[this.$store.state.current.master_i][2],
        lng: this.$store.state.masters[this.$store.state.current.master_i][3],
      }
    }
  },
  mounted: function(){
    console.log('Map', this.center);
  }
}
</script>
